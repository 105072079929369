<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {minLength, required} from "vuelidate/lib/validators";
import Master from "@/apis/Master";
import Vue from "vue";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "EDIT ROLE",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "ROLE MANAGEMENT",
          href: "/roles"
        },
        {
          text: "EDIT ROLE",
          active: true
        }
      ],

      role: {
        id: "",
        roleTitle: "",
        pages: []
      },
      submitted: false,
      editSubmitted: false,
      showmodal: false,
      pageData: [],
      roleData: [],
      successMessage: "",
      errorMessage: "",
      errorCodeMessage: "",
      editshowmodal: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
  },
  mounted() {
    // Set the initial number of items

    Master.adminSectionPageList().then((res) => {
      console.log(res)
      this.pageData = res.data.data;

    });
    Master.adminRoleDetails(
        {
          roleId:localStorage.getItem('roleId')
        }
    ).then((res) => {
      console.log(res)
      this.roleData = res.data.data;


      this.role.id = res.data.data.roleId;
      this.role.roleTitle = res.data.data.roleName;

      for (var i = 0; i <= res.data.data.rolePages.length; i++){
        this.role.pages.push(res.data.data.rolePages[i].id)
      }
    });

  },
  validations: {
    role: {
      roleTitle: { required, minLength: minLength(3) },
      pages: { required }
    }
  },
  methods: {
    /**
     * Edit Color Modal
     */
    async handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.role.$invalid) {
        return;
      } else {
        Master.adminRoleUpdate(
            {
              roleId : this.role.id,
              name : this.role.roleTitle,
              pages : this.role.pages
            }).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 2000
          });
          this.$router.push({path: '/roles'})
          this.showmodal = false;
        }).catch((err) => {
          // console.log(this.errorCodeMessage = err.response.data.error.code[0])
          this.$bvToast.toast(""+err.response.data.error.name[0]+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });


          // this.errorMessage = err.response.data.error.name[0];
          this.showmodal = true;
        })

      }
      this.submitted = false;
    },
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform: uppercase !important;">

            <form @submit.prevent="handleSubmit">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="title">ROLE TITLE *</label>
                    <input
                        id="title"
                        v-model="role.roleTitle"
                        type="text"
                        class="form-control"
                        placeholder="ENTER ROLE TITLE"
                        :class="{ 'is-invalid': submitted && $v.role.roleTitle.$error }"
                        style="text-transform: uppercase !important;"
                    />
                    <div v-if="submitted && $v.role.roleTitle.$error" class="invalid-feedback">
                      <span v-if="!$v.role.roleTitle.required">ROLE TITLE IS REQUIRED</span>
                      <span v-if="!$v.role.roleTitle.minLength">ROLE TITLE SHOULD BE ATLEAST 3 CHARACTER</span>
                    </div>
                  </div>

                </div>
              </div>

              <div class="form-group">
                <label>PAGE ACCESS </label>
                <div class="row">
                  <div class="col-md-2" v-for="(page,index) in pageData" :key="page.pageId">
                    <div  class="custom-control custom-checkbox" v-if="page.sectionName">
                      <!--                      <ul>-->
                      <!--                        <li>-->
                      <input :id="'invalidCheck'+index" type="checkbox" :value="page.pageId" v-model="role.pages" class="custom-control-input" />
                      <label
                          class="custom-control-label"
                          :for="'invalidCheck'+index"
                      >{{page.page}}</label>
                      <!--                        </li>-->
                      <!--                      </ul>-->

                    </div>
                  </div>
                  <div v-if="submitted && $v.role.pages.$error" class="error" style="font-size: 80%; margin-left: 12px; margin-top: 10px">
                    <span v-if="!$v.role.pages.required">PAGE ARE REQUIRED</span>
                  </div>
                </div>
              </div>

              <div class="text-right">
                <button type="submit" class="btn btn-success">UPDATE</button>
                <router-link to="/roles"><b-button class="ml-1 btn btn-dark"><i class="mdi mdi-arrow-left mr-2"></i> BACK</b-button></router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>